* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    min-height: 100%;
    height: 100%;
}

body {
    min-height: 100%;
    height: 100%;
    width: 100%;
    background-color: rgba(238, 238, 238, 0.5);
}

@media print {
    body {
        background: white;
    }

    @page {
        size: 210mm 297mm;
    }
}

#root {
    min-height: 100%;
    height: 100%;
}